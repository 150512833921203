import { useEffect, useMemo } from 'react'
import { Navigate } from 'react-router-dom'

import Layout from 'components/Layout/index'
import { useAuthService } from 'features/auth'
import { storage } from 'libs/core/storage'
import { Loader } from 'libs/ui/components/Loader'



const AuthLayout = () => {
  const { auth, getMe } = useAuthService()
  const canAutoLogin = !!storage.getAccessToken()
  const isAuthenticated = useMemo<boolean>(() => !!auth.me, [auth.me])

  useEffect(() => {
    if (canAutoLogin) {
      getMe();
    }
  },[canAutoLogin, getMe])

  if (isAuthenticated) {
    return <Layout />;
  }

  if (canAutoLogin) {
    return <Loader />;
  }

  return <Navigate to="/login" replace />;
}

export default AuthLayout