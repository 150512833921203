// DUCKS pattern
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { CommandRequestParam, CommandsResponse } from 'features/commands/types'
import type { RootState } from 'store/store'

export interface CommandsState {
  loading: boolean
  error: unknown | undefined
  page: number,
  pageSize: number,
  commands: CommandsResponse
}

const initialState: CommandsState = {
  loading: false,
  error: undefined,
  page: 0,
  pageSize: 10,
  commands: {
    count: 0,
    results: []
  },
}

// slice
export const commandsSlice = createSlice({
  name: 'commands',
  initialState,
  reducers: {
    // getCommands
    getCommandsStart(state) {
      state.loading = true
      state.error = undefined
    },
    getCommandsSuccess(state, action: PayloadAction<CommandsResponse>) {
      state.commands = action.payload
      state.loading = false
      state.error = undefined
    },
    getCommandsFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },

    // changePage
    changePage(state, action: PayloadAction<number>) {
      state.page = action.payload
    },
    // changePageSize
    changePageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload
    }
  },
})

// Actions
export const commandsActions = {
  getCommands: createAction(`${commandsSlice.name}/getCommands`, (param: CommandRequestParam) => ({
    payload: param
  })),
  getCommandsStart: commandsSlice.actions.getCommandsStart,
  getCommandsSuccess: commandsSlice.actions.getCommandsSuccess,
  getCommandsFailure: commandsSlice.actions.getCommandsFailure,

  changePage: commandsSlice.actions.changePage,
  changePageSize: commandsSlice.actions.changePageSize
}

// Selectors
export const selectCommands = (state: RootState) => state.commands

// Reducer
export default commandsSlice.reducer
