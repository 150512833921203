import { SagaIterator } from '@redux-saga/core'
import { call, put, takeEvery } from 'redux-saga/effects'

import { createPau, deletePau, getPaus, updatePau } from 'features/paus/api'
import { pausActions } from 'features/paus/store/paus.slice'
import { PauInput, PauResponse } from 'features/paus/types'

// Worker Sagas
function* onCreatePau({
  payload,
}: {
  type: typeof pausActions.createPau
  payload: PauInput
},
): SagaIterator {
  yield put(pausActions.createPauStart())
  try {
    yield call(createPau, payload)
    yield put(pausActions.getPaus())
  } catch (e) {
    yield put(pausActions.createPauFailure(e))
  }
}

function* onGetPaus(): SagaIterator {
  yield put(pausActions.getPausStart())
  try {
    const paus: PauResponse = yield call(getPaus)
    yield put(pausActions.getPausSuccess(paus))
  } catch (e) {
    yield put(pausActions.getPausFailure(e))
  }
}

function* onUpdatePau({
  payload,
}: {
  type: typeof pausActions.updatePau
  payload: {
    pauId: number,
    data: PauInput
  }
},
): SagaIterator {
  yield put(pausActions.updatePauStart())
  try {
    yield call(updatePau, payload.pauId, payload.data)
    yield put(pausActions.getPaus())
  } catch (e) {
    yield put(pausActions.updatePauFailure(e))
  }
}

function* onDeletePau({
  payload,
}: {
  type: typeof pausActions.deletePau
  payload: number
},
): SagaIterator {
  yield put(pausActions.deletePauStart())
  try {
    yield call(deletePau, payload)
    yield put(pausActions.getPaus())
  } catch (e) {
    yield put(pausActions.deletePauFailure(e))
  }
}

// Watcher Saga
export function* pausWatcherSaga(): SagaIterator {
  yield takeEvery(pausActions.createPau.type, onCreatePau)
  yield takeEvery(pausActions.updatePau.type, onUpdatePau)
  yield takeEvery(pausActions.getPaus.type, onGetPaus)
  yield takeEvery(pausActions.deletePau.type, onDeletePau)
}

export default pausWatcherSaga
