import { useCallback, useMemo } from 'react'

import { authActions, AuthState, selectAuth } from 'features/auth/store'
import { LoginInput } from 'features/auth/types'
import { Role } from 'features/users/types'
import { useAppDispatch, useAppSelector } from 'store/hooks'

export type AuthServiceOperators = {
  auth: AuthState
  isAdmin: boolean
  login: (data: LoginInput) => void
  logout: () => void
  getMe: () => void
}


export const useAuthService = (): Readonly<AuthServiceOperators> => {
  const dispatch = useAppDispatch()
  const auth = useAppSelector(selectAuth)

  return {
    auth,
    isAdmin: useMemo(() => [Role.ADMIN, Role.MANAGER].includes(auth.me?.role || Role.OPERATOR), [auth.me?.role]),
    login: useCallback((data: LoginInput) => dispatch(authActions.login(data)), [dispatch]),
    logout: useCallback(() => dispatch(authActions.logout()), [dispatch]),
    getMe: useCallback(() => dispatch(authActions.getMe()), [dispatch]),
  }
}

export default useAuthService
