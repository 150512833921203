// DUCKS pattern
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Device, UpdateDeviceInput } from 'features/devices/types'
import { CreateJobInput, Job, UpdateJobInput } from 'features/jobs/types'
import type { RootState } from 'store/store'

export interface JobsState {
  loading: boolean
  error: unknown | undefined
  jobs: Job[]
  job: Job | undefined
}

const initialState: JobsState = {
  loading: false,
  error: undefined,
  jobs: [],
  job: undefined,
}

// slice
export const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    // createJob
    createJobStart(state) {
      state.loading = true
      state.error = undefined
    },
    createJobSuccess(state, action: PayloadAction<Job>) {
      state.jobs.push(action.payload)
      state.loading = false
      state.error = undefined
    },
    createJobFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },

    // getJobs
    getJobsStart(state) {
      state.loading = true
      state.jobs = []
      state.error = undefined
    },
    getJobsSuccess(state, action: PayloadAction<Job[]>) {
      state.jobs = action.payload
      state.loading = false
      state.error = undefined
    },
    getJobsFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
      state.jobs = []
    },
    // getJob
    getJobStart(state) {
      state.loading = true
      state.job = undefined
      state.error = undefined
    },
    getJobSuccess(state, action: PayloadAction<Job>) {
      state.job = action.payload
      state.loading = false
      state.error = undefined
    },
    getJobFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
      state.job = undefined
    },

    // updateJob
    updateJobStart(state) {
      state.loading = true
      state.error = undefined
    },
    updateJobSuccess(state, action: PayloadAction<Job>) {
      // state.jobs = state.jobs.map(item => item.id === action.payload.id ? action.payload : item)
      state.loading = false
      state.error = undefined
    },
    updateJobFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },

    // deleteJob
    deleteJobStart(state) {
      state.loading = true
      state.error = undefined
    },
    deleteJobSuccess(state, action: PayloadAction<number>) {
      // state.job = action.payload
      const index = state.jobs.findIndex((item) => item.id === action.payload)
      if (index !== -1) {
        state.jobs.splice(index, 1)
      }
      state.loading = false
      state.error = undefined
    },
    deleteJobFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },

    // createDevice
    createDeviceStart(state) {
      state.loading = true
      state.error = undefined
    },
    createDeviceSuccess(state, action: PayloadAction<Device>) {
      // state.job?.devices?.push(action.payload)
      state.loading = false
      state.error = undefined
    },
    createDeviceFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },

    // updateDevice
    updateDeviceStart(state) {
      state.loading = true
      state.error = undefined
    },
    updateDeviceSuccess(state, action: PayloadAction<Device>) {
      // state.job?.devices?.map(item => item.id === action.payload.id ? action.payload : item)
      state.loading = false
      state.error = undefined
    },
    updateDeviceFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },

    // deleteDevice
    deleteDeviceStart(state) {
      state.loading = true
      state.error = undefined
    },
    deleteDeviceSuccess(state, action: PayloadAction<number>) {
      // const devices = state.job?.devices || []
      // const index = devices.findIndex(item => item.id === action.payload)
      // if (state.job?.devices && index !== -1) {
      //   state.job.devices.splice(index, 1)
      // }
      state.loading = false
      state.error = undefined
    },
    deleteDeviceFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },
  },
})

// Actions
export const jobsActions = {
  createJob: createAction(`${jobsSlice.name}/createJob`, (data: CreateJobInput) => ({
    payload: data
  })),
  createJobStart: jobsSlice.actions.createJobStart,
  createJobSuccess: jobsSlice.actions.createJobSuccess,
  createJobFailure: jobsSlice.actions.createJobFailure,

  getJobs: createAction(`${jobsSlice.name}/getJobs`),
  getJobsStart: jobsSlice.actions.getJobsStart,
  getJobsSuccess: jobsSlice.actions.getJobsSuccess,
  getJobsFailure: jobsSlice.actions.getJobsFailure,

  getJob: createAction(`${jobsSlice.name}/getJob`, (jobId: number) => ({ payload: jobId })),
  getJobStart: jobsSlice.actions.getJobStart,
  getJobSuccess: jobsSlice.actions.getJobSuccess,
  getJobFailure: jobsSlice.actions.getJobFailure,

  updateJob: createAction(`${jobsSlice.name}/updateJob`, (jobId: number, data: UpdateJobInput) => ({
    payload: {
      jobId,
      data,
    },
  })),
  updateJobStart: jobsSlice.actions.updateJobStart,
  updateJobSuccess: jobsSlice.actions.updateJobSuccess,
  updateJobFailure: jobsSlice.actions.updateJobFailure,

  deleteJob: createAction(`${jobsSlice.name}/deleteJob`, (jobId: number) => ({
    payload: jobId,
  })),
  deleteJobStart: jobsSlice.actions.deleteJobStart,
  deleteJobSuccess: jobsSlice.actions.deleteJobSuccess,
  deleteJobFailure: jobsSlice.actions.deleteJobFailure,

  createDevice: createAction(`${jobsSlice.name}/createDevice`, (device: Device) => ({ payload: device })),
  createDeviceStart: jobsSlice.actions.createDeviceStart,
  createDeviceSuccess: jobsSlice.actions.createDeviceSuccess,
  createDeviceFailure: jobsSlice.actions.createDeviceFailure,

  updateDevice: createAction(`${jobsSlice.name}/updateDevice`, (deviceId: number, data: UpdateDeviceInput) => ({
    payload: {
      deviceId,
      data,
    },
  })),
  updateDeviceStart: jobsSlice.actions.updateDeviceStart,
  updateDeviceSuccess: jobsSlice.actions.updateDeviceSuccess,
  updateDeviceFailure: jobsSlice.actions.updateDeviceFailure,

  deleteDevice: createAction(`${jobsSlice.name}/deleteDevice`, (deviceId: number) => ({
    payload: {
      deviceId,
    },
  })),
  deleteDeviceStart: jobsSlice.actions.deleteDeviceStart,
  deleteDeviceSuccess: jobsSlice.actions.deleteDeviceSuccess,
  deleteDeviceFailure: jobsSlice.actions.deleteDeviceFailure,
}

// Selectors
export const selectJobs = (state: RootState) => state.jobs

// Reducer
export default jobsSlice.reducer
