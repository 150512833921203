import { SagaIterator } from '@redux-saga/core'
import { call, put, takeEvery } from 'redux-saga/effects'

import { Device, UpdateDeviceInput } from 'features/devices/types'
import {
  createDevice,
  createJob,
  deleteDevice,
  deleteJob,
  getJob,
  getJobs,
  updateDevice,
  updateJob,
} from 'features/jobs/api'
import { jobsActions } from 'features/jobs/store/jobs.slice'
import { CreateJobInput, Job, UpdateJobInput } from 'features/jobs/types'

// Worker Sagas
function* onCreateJob({
  payload,
}: {
  type: typeof jobsActions.createJob
  payload: CreateJobInput
},
): SagaIterator {
  yield put(jobsActions.createJobStart())
  try {
    yield call(createJob, payload)
    yield put(jobsActions.getJobs())
  } catch (e) {
    yield put(jobsActions.createJobFailure(e))
  }
}

function* onGetJobs(): SagaIterator {
  yield put(jobsActions.getJobsStart())
  try {
    const jobs: Job[] = yield call(getJobs)
    yield put(jobsActions.getJobsSuccess(jobs))
  } catch (e) {
    yield put(jobsActions.getJobsFailure(e))
  }
}

function* onGetJob({
  payload,
}: {
  type: typeof jobsActions.getJob
  payload: number
},
): SagaIterator {
  yield put(jobsActions.getJobStart())
  try {
    const job: Job = yield call(getJob, payload)
    yield put(jobsActions.getJobSuccess(job))
  } catch (e) {
    yield put(jobsActions.getJobFailure(e))
  }
}

function* onUpdateJob({
  payload,
}: {
  type: typeof jobsActions.updateJob
  payload: {
    jobId: number,
    data: UpdateJobInput
  }
},
): SagaIterator {
  yield put(jobsActions.updateJobStart())
  try {
    yield call(updateJob, payload.jobId, payload.data)
    yield put(jobsActions.getJobs())
  } catch (e) {
    yield put(jobsActions.updateJobFailure(e))
  }
}

function* onDeleteJob({
  payload,
}: {
  type: typeof jobsActions.deleteDevice
  payload: number
},
): SagaIterator {
  yield put(jobsActions.deleteJobStart())
  try {
    yield call(deleteJob, payload)
    yield put(jobsActions.getJobs())
  } catch (e) {
    yield put(jobsActions.deleteJobFailure(e))
  }
}

function* onCreateDevice({
  payload,
}: {
  type: typeof jobsActions.createDevice
  payload: Device
},
): SagaIterator {
  yield put(jobsActions.createDeviceStart())
  try {
    const device: Device = yield call(createDevice, payload)
    yield put(jobsActions.createDeviceSuccess(device))
  } catch (e) {
    yield put(jobsActions.createDeviceFailure(e))
  }
}

function* onUpdateDevice({
  payload,
}: {
  type: typeof jobsActions.updateDevice
  payload: { deviceId: number, data: UpdateDeviceInput }
},
): SagaIterator {
  yield put(jobsActions.updateDeviceStart())
  try {
    const device: Device = yield call(updateDevice, payload.deviceId, payload.data)
    yield put(jobsActions.updateDeviceSuccess(device))
  } catch (e) {
    yield put(jobsActions.updateDeviceFailure(e))
  }
}

function* onDeleteDevice({
  payload,
}: {
  type: typeof jobsActions.deleteDevice
  payload: { deviceId: number }
},
): SagaIterator {
  yield put(jobsActions.deleteDeviceStart())
  try {
    yield call(deleteDevice, payload.deviceId)
    yield put(jobsActions.deleteDeviceSuccess(payload.deviceId))
  } catch (e) {
    yield put(jobsActions.deleteDeviceFailure(e))
  }
}

// Watcher Saga
export function* jobsWatcherSaga(): SagaIterator {
  yield takeEvery(jobsActions.createJob.type, onCreateJob)
  yield takeEvery(jobsActions.getJobs.type, onGetJobs)
  yield takeEvery(jobsActions.getJob.type, onGetJob)
  yield takeEvery(jobsActions.updateJob.type, onUpdateJob)
  yield takeEvery(jobsActions.deleteJob.type, onDeleteJob)
  yield takeEvery(jobsActions.createDevice.type, onCreateDevice)
  yield takeEvery(jobsActions.updateDevice.type, onUpdateDevice)
  yield takeEvery(jobsActions.deleteDevice.type, onDeleteDevice)
}

export default jobsWatcherSaga
