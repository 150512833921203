import ArticleIcon from '@mui/icons-material/Article'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv'
import DashboardIcon from '@mui/icons-material/Dashboard'
import DeviceHubIcon from '@mui/icons-material/DeviceHub'
import HistoryIcon from '@mui/icons-material/History'
import PeopleIcon from '@mui/icons-material/People'
import WorkIcon from '@mui/icons-material/Work'
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { drawerWidth } from 'components/Layout'
import { useAuthService } from 'features/auth'
import { Role } from 'features/users/types'

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

export interface DrawerItem {
  text: string
  link: string
  icon: JSX.Element
  roles: Role[]
}

const navItems: DrawerItem[] = [
  {
    text: 'Dashboard',
    link: '/',
    icon: <DashboardIcon />,
    roles: [Role.ADMIN, Role.MANAGER, Role.OPERATOR, Role.SUPERVISOR, Role.CREW_LEADER],
  },
  {
    text: 'Users',
    link: '/users',
    icon: <PeopleIcon />,
    roles: [Role.ADMIN, Role.MANAGER],
  },
  {
    text: 'Jobs',
    link: '/jobs',
    icon: <WorkIcon />,
    roles: [Role.ADMIN, Role.MANAGER, Role.OPERATOR, Role.SUPERVISOR, Role.CREW_LEADER],
  },
  {
    text: 'Devices',
    link: '/devices',
    icon: <DeviceHubIcon />,
    roles: [Role.ADMIN, Role.MANAGER],
  },
  {
    text: 'Pau',
    link: '/paus',
    icon: <ConnectedTvIcon />,
    roles: [Role.MANAGER, Role.ADMIN],
  },
  {
    text: 'Report',
    link: '/report',
    icon: <ArticleIcon />,
    roles: [Role.ADMIN, Role.MANAGER],
  },
  {
    text: 'History',
    link: '/history',
    icon: <HistoryIcon />,
    roles: [Role.ADMIN, Role.MANAGER],
  },
]

export type AppDrawerProps = {
  open: boolean
  handleDrawerClose: () => void
}

export const AppDrawer = ({ open, handleDrawerClose }: AppDrawerProps) => {
  const navigate = useNavigate()
  const { auth: { me } } = useAuthService()
  const navMenus = useMemo(() => navItems.filter(item => item.roles.includes(me?.role || Role.OPERATOR)), [me?.role])

  const clickItem = useCallback((item: DrawerItem) => {
    navigate(item.link)
  }, [navigate])
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant='persistent'
      anchor='left'
      open={open}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {navMenus.map((item, index) =>
          <ListItem key={index} disablePadding>
            <ListItemButton onClick={() => clickItem(item)}>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
      <Divider />
    </Drawer>
  )
}