import { SagaIterator } from '@redux-saga/core'
import { call, put, takeEvery } from 'redux-saga/effects'

import { commonActions } from 'features/common/store'
import { ApiError } from 'features/common/types'
import { createUser, deleteUser, getUsers, updateUser } from 'features/users/api'
import { usersActions } from 'features/users/store/users.slice'
import { CreateUserInput, UserRequestParam, UsersResponse } from 'features/users/types'

// Worker Sagas
function* onGetUsers({
  payload,
}: {
  type: typeof usersActions.getUsers
  payload: UserRequestParam
},
): SagaIterator {
  yield put(usersActions.getUsersStart())
  try {
    const res: UsersResponse = yield call(getUsers, payload)
    yield put(usersActions.getUsersSuccess(res))
  } catch (e) {
    const err = e as ApiError
    yield put(usersActions.getUsersFailure(err))
  }
}

function* onCreateUser({
  payload,
}: {
  type: typeof usersActions.createUser
  payload: CreateUserInput
},
): SagaIterator {
  yield put(usersActions.createUserStart())
  try {
    yield call(createUser, payload)
    yield put(usersActions.getUsers())
  } catch (e) {
    const err = e as ApiError
    yield put(usersActions.createUserFailure(err))
    yield put(commonActions.setError(err))
    yield put(commonActions.showMessage(true))
  }
}

function* onUpdateUser({
  payload,
}: {
  type: typeof usersActions.updateUser
  payload: {
    userId: number,
    user: CreateUserInput
  }
},
): SagaIterator {
  yield put(usersActions.updateUserStart())
  try {
    yield call(updateUser, payload.userId, payload.user)
    yield put(usersActions.getUsers())
  } catch (e) {
    const err = e as ApiError
    yield put(usersActions.updateUserFailure(err))
    yield put(commonActions.setError(err))
    yield put(commonActions.showMessage(true))
  }
}

function* onDeleteUser({
  payload,
}: {
  type: typeof usersActions.deleteUser
  payload: number
},
): SagaIterator {
  yield put(usersActions.deleteUserStart())
  try {
    yield call(deleteUser, payload)
    yield put(usersActions.getUsers())
  } catch (e) {
    const err = e as ApiError
    yield put(usersActions.deleteUserFailure(err))
    yield put(commonActions.setError(err))
    yield put(commonActions.showMessage(true))
  }
}

// Watcher Saga
export function* usersWatcherSaga(): SagaIterator {
  yield takeEvery(usersActions.getUsers.type, onGetUsers)
  yield takeEvery(usersActions.createUser.type, onCreateUser)
  yield takeEvery(usersActions.updateUser.type, onUpdateUser)
  yield takeEvery(usersActions.deleteUser.type, onDeleteUser)
}

export default usersWatcherSaga
