import Logout from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, styled } from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { drawerWidth } from 'components/Layout'
import { useAuthService } from 'features/auth'

import logoImage from '../../assets/images/logo.png'

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

export type HeaderProps = {
  open: boolean
  handleDrawerOpen: () => void
}

const Header = ({ open, handleDrawerOpen }: HeaderProps) => {
  const { logout } = useAuthService()
  const navigate = useNavigate()

  const onLogout = useCallback(() => {
    logout()
    navigate('/login')
  }, [logout, navigate])

  return (
    <AppBar
      position='static'
      color='transparent'
      elevation={0}
      sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}` }}
      open={open}
    >
      <Toolbar sx={{ flexWrap: 'wrap' }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ ...(open && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant='h6' color='inherit' noWrap sx={{ flexGrow: 1 }}>
          <img src={logoImage} alt={''} height={24} />
        </Typography>
        <nav>
          <IconButton color='inherit' onClick={onLogout}>
            <Logout fontSize='small' />
          </IconButton>
        </nav>
      </Toolbar>
    </AppBar>
  )
}

export default Header
