import { api } from 'features/api'
import { Device, UpdateDeviceInput } from 'features/devices/types'
import { CreateJobInput, Job, UpdateJobInput } from 'features/jobs/types'

export const createJob = (data: CreateJobInput): Promise<Job> =>
  api.post('/api/jobs/', data)

export const getJobs = (): Promise<Job[]> =>
  api.get('/api/jobs/')

export const getJob = (jobId: number): Promise<Job> =>
  api.get(`/api/jobs/${jobId}/`)

export const updateJob = (jobId: number, data: UpdateJobInput): Promise<Job> =>
  api.patch(`/api/jobs/${jobId}/`, data)

export const deleteJob = (jobId: number): Promise<unknown> =>
  api.delete(`/api/jobs/${jobId}/`)

export const createDevice = (device: Device): Promise<Device> =>
  api.post(`/api/devices/`, device)

export const updateDevice = (deviceId: number, data: UpdateDeviceInput): Promise<Device> =>
  api.patch(`/api/devices/${deviceId}/`, data)

export const deleteDevice = (deviceId: number): Promise<unknown> =>
  api.delete(`/api/devices/${deviceId}/`)