import { api } from 'features/api'
import { CreateUserInput, User, UserRequestParam, UsersResponse } from 'features/users/types'

export const getUsers= (params?: UserRequestParam): Promise<UsersResponse> =>
  api.get('/api/auth/users/', { params: params || { page: 1, page_size: 10 } })

export const createUser= (user: CreateUserInput): Promise<User> =>
  api.post('/api/auth/users/', user)

export const updateUser= (userId: number, user: CreateUserInput): Promise<User> =>
  api.patch(`/api/auth/users/${userId}/`, user)

export const deleteUser= (userId: number): Promise<unknown> =>
  api.delete(`/api/auth/users/${userId}/`)