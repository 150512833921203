class Storage {
  ACCESS_TOKEN = 'flowpoint.access'

  REFRESH_TOKEN = 'flowpoint.refresh'

  getAccessToken(): string | undefined {
    return localStorage.getItem(this.ACCESS_TOKEN) || undefined
  }

  getRefreshToken(): string | undefined {
    return localStorage.getItem(this.REFRESH_TOKEN) || undefined
  }

  setAccessToken(token: string | undefined) {
    if (!token) return
    localStorage.setItem(this.ACCESS_TOKEN, token)
  }

  setRefreshToken(token: string | undefined) {
    if (!token) return
    localStorage.setItem(this.REFRESH_TOKEN, token)
  }

  // eslint-disable-next-line class-methods-use-this
  clear() {
    localStorage.clear()
  }
}

export const storage = new Storage()