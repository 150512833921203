import { SagaIterator } from '@redux-saga/core'
import { call, put, takeEvery } from 'redux-saga/effects'

import { getMe, login } from 'features/auth/api'
import { authActions } from 'features/auth/store/auth.slice'
import { LoginInput, Token } from 'features/auth/types'
import { User } from 'features/users/types'
import { storage } from 'libs/core/storage'

// Worker Sagas
function* onLogin({
  payload,
}: {
  type: typeof authActions.login
  payload: LoginInput
}): SagaIterator {
  yield put(authActions.loginStart())
  try {
    const token: Token = yield call(login, payload)
    yield put(authActions.loginSuccess(token))
    storage.setAccessToken(token.access)
    storage.setRefreshToken(token.refresh)
    yield put(authActions.getMe())
  } catch (e: unknown) {
    yield put(authActions.loginFailure(e))
    storage.clear()
  }
}

function* onLogout(): SagaIterator {
  storage.clear()
  yield put(authActions.logoutSuccess())
}

function* onGetMe(): SagaIterator {
  yield put(authActions.getMeStart())
  try {
    const user: User = yield call(getMe)
    yield put(authActions.getMeSuccess(user))
  } catch(e) {
    yield put(authActions.getMeFailure(e))
    yield put(authActions.loginFailure(e))
    storage.clear()
  }

}

// Watcher Saga
export function* authWatcherSaga(): SagaIterator {
  yield takeEvery(authActions.login.type, onLogin)
  yield takeEvery(authActions.logout.type, onLogout)
  yield takeEvery(authActions.getMe.type, onGetMe)
}

export default authWatcherSaga
