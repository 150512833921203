import { api } from 'features/api'
import { LoginInput, Token } from 'features/auth/types'
import { User } from 'features/users/types'

export const login = (data: LoginInput): Promise<Token> =>
  api.post('/api/auth/jwt/create/', data)

export const getMe = (): Promise<User> =>
  api.get('/api/auth/users/me/')

