import { SagaIterator } from '@redux-saga/core'
import { call, put, takeEvery } from 'redux-saga/effects'

import { getCommands } from 'features/commands/api'
import { commandsActions } from 'features/commands/store/commands.slice'
import { CommandRequestParam, CommandsResponse } from 'features/commands/types'

// Worker Sagas
function* onGetCommands({
  payload,
}: {
  type: typeof commandsActions.getCommands
  payload: CommandRequestParam
},
): SagaIterator {
  yield put(commandsActions.getCommandsStart())
  try {
    const res: CommandsResponse = yield call(getCommands, payload)
    yield put(commandsActions.getCommandsSuccess(res))
  } catch (e) {
    yield put(commandsActions.getCommandsFailure(e))
  }
}

// Watcher Saga
export function* commandsWatcherSaga(): SagaIterator {
  yield takeEvery(commandsActions.getCommands.type, onGetCommands)
}

export default commandsWatcherSaga
