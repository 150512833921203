import { SagaIterator } from '@redux-saga/core'
import { call, put, takeEvery } from 'redux-saga/effects'

import { getReport, getReports } from 'features/report/api'
import { reportsActions } from 'features/report/store/reports.slice'
import { Report, ReportRequestParam, ReportsResponse } from 'features/report/types'

// Worker Sagas
function* onGetReports({
  payload,
}: {
  type: typeof reportsActions.getReports
  payload: ReportRequestParam
},
): SagaIterator {
  yield put(reportsActions.getReportsStart())
  try {
    const res: ReportsResponse = yield call(getReports, payload)
    yield put(reportsActions.getReportsSuccess(res))
  } catch (e) {
    yield put(reportsActions.getReportsFailure(e))
  }
}

function* onGetReport({
  payload,
}: {
  type: typeof reportsActions.getReport
  payload: number
},
): SagaIterator {
  yield put(reportsActions.getReportsStart())
  try {
    const report: Report = yield call(getReport, payload)
    yield put(reportsActions.getReportSuccess(report))
  } catch (e) {
    yield put(reportsActions.getReportFailure(e))
  }
}

// Watcher Saga
export function* reportsWatcherSaga(): SagaIterator {
  yield takeEvery(reportsActions.getReports.type, onGetReports)
  yield takeEvery(reportsActions.getReport.type, onGetReport)
}

export default reportsWatcherSaga
