// DUCKS pattern
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Device, DevicesRequestParam, DevicesResponse } from 'features/devices/types'
import type { RootState } from 'store/store'

export interface DeviceState {
  loading: boolean
  error: unknown | undefined
  device: Device | undefined
  page: number
  pageSize: number
  devices: DevicesResponse
}

const initialState: DeviceState = {
  loading: false,
  error: undefined,
  device: undefined,
  page: 0,
  pageSize: 10,
  devices: {
    count: 0,
    results: []
  },
}

// slice
export const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    // getDevice
    getDeviceStart(state) {
      state.loading = true
      state.device = undefined
      state.error = undefined
    },
    getDeviceSuccess(state, action: PayloadAction<Device>) {
      state.device = action.payload
      state.loading = false
      state.error = undefined
    },
    getDeviceFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
      state.device = undefined
    },

    changePage(state, action: PayloadAction<number>) {
      state.page = action.payload
    },
    changePageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload
    },

    getDevicesStart(state) {
      state.loading = true
      state.error = undefined
    },
    getDevicesSuccess(state, action: PayloadAction<DevicesResponse>) {
      state.devices = action.payload
      state.loading = false
      state.error = undefined
    },
    getDevicesFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },

    createDeviceStart(state) {
      state.loading = true
      state.error = undefined
    },
    createDeviceSuccess(state, action: PayloadAction<Device>) {
      state.loading = false
      state.error = undefined
    },
    createDeviceFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },

    updateDeviceStart(state) {
      state.loading = true
      state.error = undefined
    },
    updateDeviceSuccess(state, action: PayloadAction<Device>) {
      state.loading = false
      state.error = undefined
    },
    updateDeviceFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },

    deleteDeviceStart(state) {
      state.loading = true
      state.error = undefined
    },
    deleteDeviceSuccess(state) {
      state.loading = false
      state.error = undefined
    },
    deleteDeviceFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },
  },
})

// Actions
export const deviceActions = {
  getDevice: createAction(`${deviceSlice.name}/getDevice`, (deviceId: number) => ({ payload: deviceId })),
  getDeviceStart: deviceSlice.actions.getDeviceStart,
  getDeviceSuccess: deviceSlice.actions.getDeviceSuccess,
  getDeviceFailure: deviceSlice.actions.getDeviceFailure,

  getDevices: createAction(`${deviceSlice.name}/getDevices`, (param?: DevicesRequestParam) => ({
    payload: param
  })),
  getDevicesStart: deviceSlice.actions.getDevicesStart,
  getDevicesSuccess: deviceSlice.actions.getDevicesSuccess,
  getDevicesFailure: deviceSlice.actions.getDevicesFailure,

  changePage: deviceSlice.actions.changePage,
  changePageSize: deviceSlice.actions.changePageSize,

  createDevice: createAction(`${deviceSlice.name}/createDevice`, (device: Device) => ({
    payload: device
  })),
  createDeviceStart: deviceSlice.actions.createDeviceStart,
  createDeviceSuccess: deviceSlice.actions.createDeviceSuccess,
  createDeviceFailure: deviceSlice.actions.createDeviceFailure,

  updateDevice: createAction(`${deviceSlice.name}/updateDevice`, (deviceId: number, device: Device) => ({
    payload: { deviceId, device }
  })),
  updateDeviceStart: deviceSlice.actions.updateDeviceStart,
  updateDeviceSuccess: deviceSlice.actions.updateDeviceSuccess,
  updateDeviceFailure: deviceSlice.actions.updateDeviceFailure,

  deleteDevice: createAction(`${deviceSlice.name}/deleteDevice`, (deviceId: number) => ({
    payload: deviceId
  })),
  deleteDeviceStart: deviceSlice.actions.deleteDeviceStart,
  deleteDeviceSuccess: deviceSlice.actions.deleteDeviceSuccess,
  deleteDeviceFailure: deviceSlice.actions.deleteDeviceFailure,
}

// Selectors
export const selectDevice = (state: RootState) => state.device

// Reducer
export default deviceSlice.reducer
