import { api } from 'features/api'
import { PAU, PauInput, PauRequestParam, PauResponse } from 'features/paus/types'

export const getPaus = (params?: PauRequestParam): Promise<PauResponse> =>
  api.get('/api/paus/', { params: params || { page: 1, page_size: 10 } })

export const createPau = (data: PauInput): Promise<PAU> =>
  api.post('/api/paus/', data)

export const updatePau = (pauId: number, data: PauInput): Promise<PAU> =>
  api.patch(`/api/paus/${pauId}/`, data)

export const deletePau = (pauId: number): Promise<unknown> =>
  api.delete(`/api/paus/${pauId}/`)