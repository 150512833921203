export enum Role {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  SUPERVISOR = 'SUPERVISOR',
  CREW_LEADER = 'CREW_LEADER',
  OPERATOR = 'OPERATOR',
}

export type User = {
  id: number
  email: string
  username: string
  first_name: string
  last_name: string
  role: Role
}

export type CreateUserInput = Partial<User & {
  id?: number
  password: string
  re_password: string
}>

export type UsersResponse = {
  count: number
  results: User[]
}

export type UserRequestParam = {
  page: number
  page_size: number
}