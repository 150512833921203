import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import Link from '@mui/material/Link'
import React from 'react'
import { Navigate } from 'react-router-dom'

import logoImage from 'assets/images/logo.png'
import { LoginForm } from 'features/auth/components/LoginForm'
import { useAuthService } from 'features/auth/hooks/useAuthService'

const Container = styled.div(() => ({
  width: '100vw',
  height: '100vh',
  background: '#063970',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const LoginCard = styled.div(() => ({
  width: '30%',
  height: 350,
  minWidth: 300,
  background: '#ffffff',
  borderRadius: 20,
  padding: 30,

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}))

const Logo = styled.img(() => ({
  height: 40,
}))


export const LoginContainer = () => {
  const { login, auth } = useAuthService()

  if (auth.token?.access) {
    return <Navigate to='/dashboard' replace />
  }

  return (
    <Container>
      <LoginCard>
        <Grid display={'flex'} justifyContent={'center'}>
          <Logo src={logoImage} />
        </Grid>
        <LoginForm onSubmit={login} />
        <Grid display={'flex'} justifyContent={'flex-end'}>
          <Link href='#' underline='none'>{'Forgot Password'}</Link>
        </Grid>
      </LoginCard>
    </Container>
  )
}
