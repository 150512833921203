// DUCKS pattern
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { LoginInput, Token } from 'features/auth/types'
import { User } from 'features/users/types'
import { storage } from 'libs/core/storage'
import type { RootState } from 'store/store'

export interface AuthState {
  loading: boolean
  error: unknown | undefined
  token: Token | undefined
  me: User | undefined
}

const initialState: AuthState = {
  loading: false,
  token: {
    access: storage.getAccessToken(),
    refresh: storage.getRefreshToken(),
  },
  me: undefined,
  error: undefined
}

// slice
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // login
    loginStart(state) {
      state.loading = true
      state.token = undefined
      state.error = undefined
    },
    loginSuccess(state, action: PayloadAction<Token>) {
      state.token = action.payload
      state.loading = false
      state.error = undefined
    },
    loginFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
      state.token = undefined
    },
    // logout
    logoutSuccess(state) {
      state.token = undefined
    },
    // getMe
    getMeStart(state) {
      state.loading = true
      state.me = undefined
      state.error = undefined
    },
    getMeSuccess(state, action: PayloadAction<User>) {
      state.me = action.payload
      state.loading = false
      state.error = undefined
    },
    getMeFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
      state.me = undefined
    },
  },
})

// Actions
export const authActions = {
  login: createAction(`${authSlice.name}/login`, (data: LoginInput) => ({
    payload: data,
  })),
  loginStart: authSlice.actions.loginStart,
  loginSuccess: authSlice.actions.loginSuccess,
  loginFailure: authSlice.actions.loginFailure,

  logout: createAction(`${authSlice.name}/logout`),
  logoutSuccess: authSlice.actions.logoutSuccess,

  getMe: createAction(`${authSlice.name}/getMe`),
  getMeStart: authSlice.actions.getMeStart,
  getMeSuccess: authSlice.actions.getMeSuccess,
  getMeFailure: authSlice.actions.getMeFailure,
}

// Selectors
export const selectAuth = (state: RootState) => state.auth

// Reducer
export default authSlice.reducer
