import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'
import Stack from '@mui/material/Stack'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { LoginInput } from 'features/auth/types'
import { Spacer } from 'libs/ui/components/common/Spacer'
import { FormPasswordField } from "libs/ui/components/FormPasswordField";
import { FormTextField } from 'libs/ui/components/FormTextField'

export type LoginFormProps = {
  onSubmit: (data: LoginInput) => void
}

export const LoginForm = (props: LoginFormProps) => {
  const { t } = useTranslation()
  const { onSubmit } = props

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('auth.login.form.required')),
    password: Yup.string().required(t('auth.login.form.required')),
  })

  const methods = useForm<LoginInput>({
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(validationSchema),
  })
  const { handleSubmit, control } = methods

  return (
    <Stack sx={{ pt: 0 }} direction='column' spacing={1} justifyContent='center'>
      <FormTextField name='email' label={'Email Address'} control={control} />
      <Spacer height={8} />
      <FormPasswordField name='password' label={'Password'} control={control} />
      <Spacer height={8} />
      <Button onClick={handleSubmit(onSubmit)} variant={'contained'}>{'Log In'}</Button>
    </Stack>
  )
}
