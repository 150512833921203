import { SagaIterator } from '@redux-saga/core'
import { call, put, takeEvery } from 'redux-saga/effects'

import { getDevice, getDevices } from 'features/devices/api'
import { deviceActions } from 'features/devices/store/device.slice'
import { Device, DevicesRequestParam, DevicesResponse } from 'features/devices/types'
import { createDevice, updateDevice, deleteDevice } from 'features/jobs/api';


function* onGetDevices({
  payload,
}: {
  type: typeof deviceActions.getDevices
  payload: DevicesRequestParam
},
): SagaIterator {
  yield put(deviceActions.getDevicesStart())
  try {
    const res: DevicesResponse = yield call(getDevices, payload)
    yield put(deviceActions.getDevicesSuccess(res))
  } catch (e) {
    yield put(deviceActions.getDeviceFailure(e))
  }
}

// Worker Sagas
function* onGetDevice({
  payload,
}: {
  type: typeof deviceActions.getDevice
  payload: number
},
): SagaIterator {
  yield put(deviceActions.getDeviceStart())
  try {
    const device: Device = yield call(getDevice, payload)
    yield put(deviceActions.getDeviceSuccess(device))
  } catch (e) {
    yield put(deviceActions.getDeviceFailure(e))
  }
}

function* onCreateDevice({
  payload,
}: {
  type: typeof deviceActions.createDevice
  payload: Device
},
): SagaIterator {
  yield put(deviceActions.createDeviceStart())
  try {
    yield call(createDevice, payload)
    yield put(deviceActions.getDevices())
  } catch (e) {
    yield put(deviceActions.createDeviceFailure(e))
  }
}

function* onUpdateDevice({
  payload,
}: {
  type: typeof deviceActions.updateDevice
  payload: {
    deviceId: number,
    device: Device
  }
},
): SagaIterator {
  yield put(deviceActions.updateDeviceStart())
  try {
    yield call(updateDevice, payload.deviceId, payload.device)
    yield put(deviceActions.getDevices())
  } catch (e) {
    yield put(deviceActions.updateDeviceFailure(e))
  }
}

function* onDeleteDevice({
  payload,
}: {
  type: typeof deviceActions.deleteDevice
  payload: number
},
): SagaIterator {
  yield put(deviceActions.deleteDeviceStart())
  try {
    yield call(deleteDevice, payload)
    yield put(deviceActions.getDevices())
  } catch (e) {
    yield put(deviceActions.deleteDeviceFailure(e))
  }
}

// Watcher Saga
export function* deviceWatcherSaga(): SagaIterator {
  yield takeEvery(deviceActions.getDevices.type, onGetDevices)
  yield takeEvery(deviceActions.getDevice.type, onGetDevice)
  yield takeEvery(deviceActions.createDevice.type, onCreateDevice)
  yield takeEvery(deviceActions.updateDevice.type, onUpdateDevice)
  yield takeEvery(deviceActions.deleteDevice.type, onDeleteDevice)
}

export default deviceWatcherSaga
