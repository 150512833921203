// DUCKS pattern
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ApiError } from 'features/common/types'
import type { RootState } from 'store/store'

export interface CommonState {
  loading: boolean
  alert: boolean
  error: undefined | ApiError
}

const initialState: CommonState = {
  loading: false,
  alert: false,
  error: undefined
}

// slice
export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    showMessage(state, action: PayloadAction<boolean>) {
      state.alert = action.payload
    },
    setError(state, action: PayloadAction<ApiError | undefined>) {
      state.error = action.payload
    },
    showLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
  },
})

// Actions
export const commonActions = {
  showMessage: commonSlice.actions.showMessage,
  setError: commonSlice.actions.setError,
  showLoading: commonSlice.actions.showLoading,
}

// Selectors
export const selectCommon = (state: RootState) => state.common

// Reducer
export default commonSlice.reducer
