// DUCKS pattern
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PAU, PauInput, PauRequestParam, PauResponse } from 'features/paus/types'
import type { RootState } from 'store/store'

export interface PausState {
  loading: boolean
  error: unknown | undefined
  page: number
  pageSize: number
  paus: PauResponse
}

const initialState: PausState = {
  loading: false,
  error: undefined,
  page: 0,
  pageSize: 10,
  paus: {
    count: 0,
    results: []
  },
}

// slice
export const pausSlice = createSlice({
  name: 'paus',
  initialState,
  reducers: {
    // getPaus
    getPausStart(state) {
      state.loading = true
      state.error = undefined
    },
    getPausSuccess(state, action: PayloadAction<PauResponse>) {
      state.paus = action.payload
      state.loading = false
      state.error = undefined
    },
    getPausFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },

    // changePage
    changePage(state, action: PayloadAction<number>) {
      state.page = action.payload
    },
    // changePageSize
    changePageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload
    },

    // createPau
    createPauStart(state) {
      state.loading = true
      state.error = undefined
    },
    createPauSuccess(state, action: PayloadAction<PAU>) {
      // state.job = action.payload
      state.loading = false
      state.error = undefined
    },
    createPauFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },

    // updatePau
    updatePauStart(state) {
      state.loading = true
      state.error = undefined
    },
    updatePauSuccess(state, action: PayloadAction<PAU>) {
      // state.job = action.payload
      state.loading = false
      state.error = undefined
    },
    updatePauFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },

    // deletePau
    deletePauStart(state) {
      state.loading = true
      state.error = undefined
    },
    deletePauSuccess(state, action: PayloadAction<number>) {
      // state.job = action.payload
      state.loading = false
      state.error = undefined
    },
    deletePauFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },
  },
})

// Actions
export const pausActions = {
  getPaus: createAction(`${pausSlice.name}/getPaus`, (param?: PauRequestParam) => ({
    payload: param
  })),
  getPausStart: pausSlice.actions.getPausStart,
  getPausSuccess: pausSlice.actions.getPausSuccess,
  getPausFailure: pausSlice.actions.getPausFailure,

  changePage: pausSlice.actions.changePage,
  changePageSize: pausSlice.actions.changePageSize,

  createPau: createAction(`${pausSlice.name}/createPau`, (data: PauInput) => ({
    payload: data,
  })),
  createPauStart: pausSlice.actions.createPauStart,
  createPauSuccess: pausSlice.actions.createPauSuccess,
  createPauFailure: pausSlice.actions.createPauFailure,

  updatePau: createAction(`${pausSlice.name}/updatePau`, (pauId: number, data: PauInput) => ({
    payload: { pauId, data },
  })),
  updatePauStart: pausSlice.actions.updatePauStart,
  updatePauSuccess: pausSlice.actions.updatePauSuccess,
  updatePauFailure: pausSlice.actions.updatePauFailure,

  deletePau: createAction(`${pausSlice.name}/deletePau`, (pauId: number) => ({
    payload: pauId,
  })),
  deletePauStart: pausSlice.actions.deletePauStart,
  deletePauSuccess: pausSlice.actions.deletePauSuccess,
  deletePauFailure: pausSlice.actions.deletePauFailure,
}

// Selectors
export const selectPaus = (state: RootState) => state.paus

// Reducer
export default pausSlice.reducer
