import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import AuthLayout from 'components/Layout/AuthLayout'
import { useAuthService } from 'features/auth'
import { Role } from 'features/users/types'
import { Loader } from 'libs/ui/components/Loader'

const HistoryPage = React.lazy(() => import('pages/HistoryPage'))
const LoginPage = React.lazy(() => import('pages/LoginPage'))
const DashboardPage = React.lazy(() => import('pages/DashboardPage'))
const JobsPage = React.lazy(() => import('pages/JobsPage'))
const PausPage = React.lazy(() => import('pages/PausPage'))
const JobDetailPage = React.lazy(() => import('pages/JobDetailPage'))
const DevicesPage = React.lazy(() => import('pages/DevicesPage'))
const DevicePage = React.lazy(() => import('pages/DevicePage'))
const MeterPage = React.lazy(() => import('pages/MeterPage'))
const ReportPage = React.lazy(() => import('pages/ReportPage'))
const ReportDetailPage = React.lazy(() => import('pages/ReportDetailPage'))
const UsersPage = React.lazy(() => import('pages/UsersPage'))
const AccessDeniedPage = React.lazy(() => import('pages/AccessDeniedPage'))
const NotFoundPage = React.lazy(() => import('pages/NotFoundPage'))


type RoleRouteProps = {
  roles: Role[]
  element: JSX.Element
}

const RoleRoute = ({ roles, element }: RoleRouteProps) => {
  const { auth: { me } } = useAuthService()
  return roles.includes(me?.role || Role.OPERATOR) ? element : <AccessDeniedPage />
}


const AppRoutes = () => (
  <Suspense fallback={<Loader />}>
    <Routes>
      <Route path='/login' element={<LoginPage />} />
      <Route path='/' element={<AuthLayout />}>
        <Route index={true} element={<Navigate to='/dashboard' replace />} />
        <Route
          path='/dashboard'
          element={
            <RoleRoute
              element={<DashboardPage />}
              roles={[Role.ADMIN, Role.MANAGER, Role.OPERATOR, Role.SUPERVISOR, Role.CREW_LEADER]}
            />
          }
        />
        <Route
          path='/users'
          element={<RoleRoute element={<UsersPage />} roles={[Role.ADMIN, Role.MANAGER]} />}
        />
        <Route
          path='/jobs'
          element={
            <RoleRoute
              element={<JobsPage />}
              roles={[Role.ADMIN, Role.MANAGER, Role.OPERATOR, Role.SUPERVISOR, Role.CREW_LEADER]}
            />
          }
        />
        <Route
          path='/jobs/:jobId'
          element={
            <RoleRoute
              element={<JobDetailPage />}
              roles={[Role.ADMIN, Role.MANAGER, Role.OPERATOR, Role.SUPERVISOR, Role.CREW_LEADER]}
            />
          }
        />
        <Route
          path='/paus'
          element={
            <RoleRoute
              element={<PausPage />}
              roles={[Role.ADMIN, Role.MANAGER]}
            />
          }
        />
        <Route
          path='/devices'
          element={
            <RoleRoute
              element={<DevicesPage />}
              roles={[Role.ADMIN, Role.MANAGER]}
            />
          }
        />
        <Route
          path='/devices/:deviceId'
          element={
            <RoleRoute
              element={<DevicePage />}
              roles={[Role.ADMIN, Role.MANAGER, Role.OPERATOR, Role.SUPERVISOR, Role.CREW_LEADER]}
            />
          }
        />
        <Route
          path='/meter/:meterId'
          element={
            <RoleRoute
              element={<MeterPage />}
              roles={[Role.ADMIN, Role.MANAGER, Role.OPERATOR, Role.SUPERVISOR, Role.CREW_LEADER]}
            />
          }
        />
        <Route
          path='/history'
          element={<RoleRoute element={<HistoryPage />} roles={[Role.ADMIN, Role.MANAGER]} />}
        />
        <Route
          path='/report'
          element={<RoleRoute element={<ReportPage />} roles={[Role.ADMIN, Role.MANAGER]} />}
        />
        <Route
          path='/report/:reportId'
          element={<RoleRoute element={<ReportDetailPage />} roles={[Role.ADMIN, Role.MANAGER]} />}
        />
        <Route path='*' element={<NotFoundPage />} />
      </Route>
    </Routes>
  </Suspense>
)

export default AppRoutes
