import { all, fork } from 'redux-saga/effects'

import { authWatcherSaga } from 'features/auth/store/auth.sagas'
import { commandsWatcherSaga } from 'features/commands/store/commands.sagas'
import { deviceWatcherSaga } from 'features/devices/store/device.sagas'
import { jobsWatcherSaga } from 'features/jobs/store/jobs.sagas'
import { pausWatcherSaga } from 'features/paus/store/paus.sagas'
import { postsWatcherSaga } from 'features/posts/store/posts.sagas'
import { reportsWatcherSaga } from 'features/report/store';
import { usersWatcherSaga } from 'features/users/store';

export function* rootSaga() {
  yield all([
    fork(postsWatcherSaga),
    fork(authWatcherSaga),
    fork(commandsWatcherSaga),
    fork(jobsWatcherSaga),
    fork(deviceWatcherSaga),
    fork(pausWatcherSaga),
    fork(reportsWatcherSaga),
    fork(usersWatcherSaga)
  ])
}

export default rootSaga
