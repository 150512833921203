import { CssBaseline, styled, ThemeProvider } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { ConfirmProvider } from 'material-ui-confirm'
import React from 'react'
import { Outlet } from 'react-router-dom'

import { AppDrawer } from 'components/AppDrawer'
import Header from 'components/Header/Header'
import { useCommonService } from 'features/common/hooks'
import { AlertSnackbar } from 'libs/ui/components/AlertSnackbar'


export const drawerWidth = 240

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  paddingTop: 24,
  paddingBottom: 24,
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${drawerWidth}px`,
  }),
}))

const Layout = () => {
  const [mode] = React.useState<'light' | 'dark'>('light')
  const [open, setOpen] = React.useState(true)
  const { common: { alert, error }, showMessage } = useCommonService()

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode],
  )

  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider>
        <CssBaseline />
        <Header open={open} handleDrawerOpen={handleDrawerOpen} />
        <AppDrawer open={open} handleDrawerClose={handleDrawerClose} />
        <Main theme={theme} open={open}>
          <Outlet />
        </Main>
        <AlertSnackbar open={alert} message={error?.message || ''} type={'error'} callback={() => showMessage(false)} />
      </ConfirmProvider>
    </ThemeProvider>
  )
}

export default Layout
