// DUCKS pattern
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ApiError } from 'features/common/types'
import { CreateUserInput, User, UserRequestParam, UsersResponse } from 'features/users/types'
import type { RootState } from 'store/store'

export interface UsersState {
  loading: boolean
  error: ApiError | undefined
  page: number,
  pageSize: number,
  users: UsersResponse
}

const initialState: UsersState = {
  loading: false,
  error: undefined,
  page: 0,
  pageSize: 10,
  users: {
    count: 0,
    results: []
  },
}

// slice
export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // getUsers
    getUsersStart(state) {
      state.loading = true
      state.error = undefined
    },
    getUsersSuccess(state, action: PayloadAction<UsersResponse>) {
      state.users = action.payload
      state.loading = false
      state.error = undefined
    },
    getUsersFailure(state, action: PayloadAction<ApiError | undefined>) {
      state.loading = false
      state.error = action.payload
    },

    // changePage
    changePage(state, action: PayloadAction<number>) {
      state.page = action.payload
    },
    // changePageSize
    changePageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload
    },

    // createUser
    createUserStart(state) {
      state.loading = true
      state.error = undefined
    },
    createUserSuccess(state, action: PayloadAction<User>) {
      state.loading = false
      state.error = undefined
    },
    createUserFailure(state, action: PayloadAction<ApiError | undefined>) {
      state.loading = false
      state.error = action.payload
    },

    // updateUser
    updateUserStart(state) {
      state.loading = true
      state.error = undefined
    },
    updateUserSuccess(state, action: PayloadAction<User>) {
      state.loading = false
      state.error = undefined
    },
    updateUserFailure(state, action: PayloadAction<ApiError | undefined>) {
      state.loading = false
      state.error = action.payload
    },

    // deleteUser
    deleteUserStart(state) {
      state.loading = true
      state.error = undefined
    },
    deleteUserSuccess(state) {
      state.loading = false
      state.error = undefined
    },
    deleteUserFailure(state, action: PayloadAction<ApiError | undefined>) {
      state.loading = false
      state.error = action.payload
    },
  },
})

// Actions
export const usersActions = {
  getUsers: createAction(`${usersSlice.name}/getUsers`, (param?: UserRequestParam) => ({
    payload: param
  })),
  getUsersStart: usersSlice.actions.getUsersStart,
  getUsersSuccess: usersSlice.actions.getUsersSuccess,
  getUsersFailure: usersSlice.actions.getUsersFailure,

  changePage: usersSlice.actions.changePage,
  changePageSize: usersSlice.actions.changePageSize,

  createUser: createAction(`${usersSlice.name}/createUser`, (user: CreateUserInput) => ({
    payload: user
  })),
  createUserStart: usersSlice.actions.createUserStart,
  createUserSuccess: usersSlice.actions.createUserSuccess,
  createUserFailure: usersSlice.actions.createUserFailure,

  updateUser: createAction(`${usersSlice.name}/updateUser`, (userId: number, user: CreateUserInput) => ({
    payload: { userId, user }
  })),
  updateUserStart: usersSlice.actions.updateUserStart,
  updateUserSuccess: usersSlice.actions.updateUserSuccess,
  updateUserFailure: usersSlice.actions.updateUserFailure,

  deleteUser: createAction(`${usersSlice.name}/deleteUser`, (userId: number) => ({
    payload: userId
  })),
  deleteUserStart: usersSlice.actions.deleteUserStart,
  deleteUserSuccess: usersSlice.actions.deleteUserSuccess,
  deleteUserFailure: usersSlice.actions.deleteUserFailure,
}

// Selectors
export const selectUsers = (state: RootState) => state.users

// Reducer
export default usersSlice.reducer
