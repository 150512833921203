import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Report, ReportRequestParam, ReportsResponse } from 'features/report/types'
import type { RootState } from 'store/store'

export interface ReportsState {
  loading: boolean
  error: unknown | undefined
  page: number,
  pageSize: number,
  reports: ReportsResponse
  report: Report | undefined
}

const initialState: ReportsState = {
  loading: false,
  error: undefined,
  page: 0,
  pageSize: 10,
  reports: {
    count: 0,
    results: []
  },
  report: undefined
}

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    getReportsStart(state) {
      state.loading = true
      state.error = undefined
    },
    getReportsSuccess(state, action: PayloadAction<ReportsResponse>) {
      state.reports = action.payload
      state.loading = false
      state.error = undefined
    },
    getReportsFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },

    // changePage
    changePage(state, action: PayloadAction<number>) {
      state.page = action.payload
    },
    // changePageSize
    changePageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload
    },

    // getReport
    getReportStart(state) {
      state.loading = true
      state.error = undefined
    },
    getReportSuccess(state, action: PayloadAction<Report>) {
      state.report = action.payload
      state.loading = false
      state.error = undefined
    },
    getReportFailure(state, action: PayloadAction<unknown>) {
      state.loading = false
      state.error = action.payload
    },
  },
})

// Actions
export const reportsActions = {
  getReports: createAction(`${reportsSlice.name}/getReports`, (param: ReportRequestParam) => ({
    payload: param
  })),
  getReportsStart: reportsSlice.actions.getReportsStart,
  getReportsSuccess: reportsSlice.actions.getReportsSuccess,
  getReportsFailure: reportsSlice.actions.getReportsFailure,

  changePage: reportsSlice.actions.changePage,
  changePageSize: reportsSlice.actions.changePageSize,

  getReport: createAction(`${reportsSlice.name}/getReport`, (reportId: number) => ({
    payload: reportId
  })),
  getReportStart: reportsSlice.actions.getReportStart,
  getReportSuccess: reportsSlice.actions.getReportSuccess,
  getReportFailure: reportsSlice.actions.getReportFailure,
}

// Selectors
export const selectReports = (state: RootState) => state.reports

// Reducer
export default reportsSlice.reducer
